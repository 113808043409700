exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-keyword-js": () => import("./../../../src/templates/keyword.js" /* webpackChunkName: "component---src-templates-keyword-js" */),
  "component---src-templates-keyword-list-js": () => import("./../../../src/templates/keyword-list.js" /* webpackChunkName: "component---src-templates-keyword-list-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-list-js": () => import("./../../../src/templates/product-list.js" /* webpackChunkName: "component---src-templates-product-list-js" */)
}

